<template>
  <div>

     <div class="footerBar">
       <div class="rightBtn">
         <el-button type="info" size="medium" @click="cancalFun" plain>取消</el-button>
         <el-button type="primary" size="medium" @click="submitFun('goodArr')">确认提交</el-button>
       </div>
     </div>
     <div class="contents">
         <el-breadcrumb separator-class="el-icon-arrow-right" style="padding:10px 10px 15px">
    <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>机器人</el-breadcrumb-item>
    <el-breadcrumb-item>渠道群管理</el-breadcrumb-item>
  </el-breadcrumb>
       <div class="mainbox">
  <div class="formTable">
        <el-form ref="goodArr" :model="goodArr" :rules="rules" label-position="right" label-width="300px">
          <el-form-item label="选择机器人：" prop="botId" style="margin-bottom:10px">
            <el-select v-model="goodArr.botId" :disabled="boname?true:false" placeholder="请选择" @change="changeAdmin">
              <el-option
                v-for="item in groupCode"
                :key="item.id"
                :label="item.name"
                :value="item.botId">
              </el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="群名称：" prop="groupId" style="margin-bottom:10px">
            <el-select v-model="goodArr.groupId" filterable :disabled="boname?true:false" clearable placeholder="请选择" @change="qunClicks">
              <el-option
                v-for="item in Groupoptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
             <el-button type="text" size="medium" style="margin-left:10px" @click="getGroupMember" plain>找不到群？试试在群里说说话</el-button>
          </el-form-item>
          <el-form-item label="渠道(团长)名称：" prop="name" style="margin-bottom:10px;width:620px;">
            <el-input v-model="goodArr.name" placeholder="请输入分销商名称"></el-input>            
            <label style="color:red;font-size:12px;float:left;">请填写您的分销商名称，如遇名称重复提示，请修改此名称</label>
            <label></label>
          </el-form-item>
          <el-form-item label="对方财务负责人：" prop="channelOwner" style="margin-bottom:10px">
            <el-select v-model="goodArr.channelOwner" filterable placeholder="请选择" @change="ownerClicks">
              <el-option
                v-for="item in memberoptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
              <label class="tips">将账单发送给@此人</label>
          </el-form-item>
          <el-form-item label="是否启用机器人：">
            <el-radio-group v-model="goodArr.valid">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
              <label class="tips">启用机器人，机器人会7x24小时为您处理群里面的Excel文件消息</label>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否自动收单：">
            <el-radio-group v-model="goodArr.autoReceive">
             <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
              <label class="tips">开启自动收单，发到此群的Excel订单文件会自动导入系统</label>
            </el-radio-group>
          </el-form-item>

           <el-form-item label="是否自动返单：">
            <el-radio-group v-model="goodArr.autoReturn">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
              <label class="tips">开启自动返单，物流单号会自动分发到各个渠道群</label>
            </el-radio-group>
          </el-form-item>
          
          <el-form-item label="自动返单规则："  v-if="goodArr.autoReturn==1">
            <el-radio-group v-model="goodArr.autoReturnRules.type">
              <el-radio label="immediately" value="immediately">即时返单</el-radio>
              <label class="tips">导入快递单号，机器人立即自动将快递单号拆分表格后发送到订单来源渠道群</label>
              <div style="clear:both;margin-top:15px;"></div>
              <el-radio label="cronjob" value="cronjob">定时返单</el-radio>
              <label class="tips">每日定时自动将已发货订单物流单号返给来源渠道群</label>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="自动返单时间：" v-if="goodArr.autoReturn==1 && goodArr.autoReturnRules.type=='cronjob'">
            <div class="timebox">
            <div class="additem" @click="addfun">+</div>
            <div v-for="(item, index) in deadlines" :key="index" style="margin-bottom:10px">
            <el-time-picker
                v-model="item.value"
                placeholder="选择返单时间，点击+添加"
                format="HH:mm"
                value-format="HH:mm"
              >
            </el-time-picker>
            <div class="delitem" v-if="index>0" @click="delfun(index)">×</div>
            </div>
            </div>
            <label class="tips">开启自动返单，已发货的订单，会在设置的返时间自动推送订单Excel到渠道群</label>
          </el-form-item>

          <el-form-item label="返单文件：">
            <el-radio-group v-model="goodArr.returnFileHandler">
              <el-radio :label="0">只返已发货订单</el-radio>
              <el-radio :label="1">原文件的订单全返</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="返单时多个快递单号显示形式：">
            <el-radio-group v-model="goodArr.multiExpressLines">
              <el-radio :label="0">一行（多条快递单号合并一行，用;分隔）</el-radio>
              <el-radio :label="1">多行（多条快递单号一行一条）</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="返单通知人微信：" style="margin-bottom:10px" prop="returnNotifyMember">
            <el-select v-model="goodArr.returnNotifyMember" clearable placeholder="请选择" @change="activeClicks">
              <el-option
                v-for="item in memberoptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <label class="tips">&nbsp;&nbsp;返单文件消息会@此人</label>
          </el-form-item>

          <el-form-item label="禁收消息的群成员列表：" style="margin-bottom:10px">
            <el-select v-model="goodArr.disableMembers" filterable clearable multiple placeholder="请选择" @change="contactClicks">
              <el-option
                v-for="item in memberoptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <label class="tips">&nbsp;&nbsp;机器人不接收此成员的消息</label>
          </el-form-item>

          <el-form-item label="机器人不接收文件名关键字：" style="margin-bottom:10px;width:800px;">
            <el-input v-model="goodArr.disableFileNames" placeholder="请输入不接收文件名关键字"></el-input>
            <span class="tips">(文件名称中包含设置的关键字，机器人不自动收单。多个关键词之间用逗号","隔开)</span>
          </el-form-item>

           <el-form-item label="备注：" style="margin-bottom:10px;width:600px;">
            <el-input type="textarea" :rows="3" v-model="goodArr.remark" placeholder="请输入渠道备注"></el-input>
          </el-form-item>

        </el-form>
       </div>
       </div>   
    </div>
  </div>
</template>

<script>
import { configRetailerGroup, getBotList,contactFun,groupFun, groupMember, retailGroupDetail } from "@/api/robot";
let moment = require('moment');
export default {
  name: 'ChannelMain',
  data() {
    return {
      goodArr: {
        id: '',
        name:'',
        botId: '',
        robotId: '',
        autoReturn: 1,
        returnFileHandler: 0,
        autoReceive: 1,
        channelOwner: null,
        groupId: '',
        groupName: '',
        multiExpressLines: 0,
        autoReceiptWhenReceive: 1,
        autoReceiptWhenReturn: 1,
        remark: '',
        autoReturnRules: {type: 'immediately'},
        ownerObj: null,
        contactObj: null,
        activeObj: null,
        returnNotifyMember: null,
        disableFileNames: "异常,账单",
        disableMembers: null,
        valid: 1,
      },
      options: [],
      Groupoptions: [],
      boname: '',
      qid: '',
      checked: false,
      classflag: false,
      showgood: false,
      loading: false,
      groupCode: [],
      tableData: [],
      goodsData: [],
      memberoptions: [],
      page: 1,
      pageSize: 10,
      total:0,
      multipleSelection: [],
      rules: {
        groupId: [{ required: true, message: "请选择群名称", trigger: "change" }],
        botId: [{ required: true, message: "请选择机器人", trigger: "change" }],
        returnNotifyMember: [{ required: true, message: "请选择返单通知人微信", trigger: "change" }],
      },
      deadlines: [{value:""}],
    }
  },
  created: function () {
   
  },
  methods:{
    handleClose(){
      this.showgood = false;
    },
    addfun(){
      var objs= {
        value: ''
      }
      this.deadlines.unshift(objs)
    },
    delfun(n){
      this.deadlines.splice(n,1)
    },
    getBodList(){
      var that = this
      var datas = {}
      datas.pageNo = 1;
      datas.pageSize = 100;
      datas.orderByCreated = "DESC";
      getBotList(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.groupCode = res.data.rows
          if(that.boname){
            that.groupCode.map((item)=>{
                if(item.botId == that.boname){
                  that.goodArr.robotId = item.id
                  return
                }
            })
          }
        }else{
          this.$message.error(res.message)
        }
      })
    },
    changeAdmin(e){
      console.log(e)
      var that = this
      that.groupCode.map((item)=>{
        if(item.botId == e){
          that.goodArr.robotId = item.id
        }
      })
      console.log(that.goodArr.robotId)
      that.getGroupList(e)
    },
    getAdminList(botId){
      var that = this
      var datas = {
        botId: botId
      }
      contactFun(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.options = res.data.contacts
        }else{
          this.$message.error(res.message)
        }
      })
    },
    getGroupList(botId){
      var that = this
      var datas = {
        botId: botId
      }
      groupFun(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.Groupoptions = res.data.groups
          if(that.qid){
            that.Groupoptions.map((item)=>{
              if(item.id==that.qid){
                that.goodArr.groupName = item.name
                that.goodArr.name = item.name
                return
              }
            })
          }
        }else{
          this.$message.error(res.message)
        }
      })
    },
    getGroupMember(){
      var that = this
      if(!that.goodArr.botId || !that.goodArr.groupId){
        that.$message.error('请先选择群组和所绑定的机器人')
        return false
      }
      var datas = {
        botId: that.goodArr.botId,
        groupId: that.goodArr.groupId
      }
      groupMember(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.memberoptions = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getGoodList()
    },
    getDetail(ids){
      var that = this
      var objs = {}
      objs.id = ids
      retailGroupDetail(objs).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.goodArr.date = []
          that.goodArr.id = res.data.id
          that.goodArr.name = res.data.name
          that.goodArr.remark = res.data.remark
          that.goodArr.valid = res.data.valid
          that.goodArr.groupId = res.data.groupId
          that.goodArr.groupName = res.data.groupName
          that.goodArr.botId = res.data.botId
          that.goodArr.robotId = res.data.robotId
          that.goodArr.autoReceive = res.data.autoReceive
          that.goodArr.autoReturn = res.data.autoReturn
          that.goodArr.autoReturnRules = res.data.autoReturnRules
          that.goodArr.returnFileHandler = res.data.returnFileHandler
          that.goodArr.autoReceiptWhenReceive = res.data.autoReceiptWhenReceive
          that.goodArr.autoReceiptWhenReturn = res.data.autoReceiptWhenReturn
          res.data.deadlines.forEach(element => {
            that.deadlines.push({value: element})
          });
          if(that.deadlines.length == 0) {
            that.deadlines = [{value:""}]
          }

          var disableFileNameArr = res.data.disableFileNames || []
          var disableFileTxt= '';
          if(disableFileNameArr.length > 0){
            disableFileNameArr.map((item)=>{
              disableFileTxt+=item+','
            })
          }
          that.goodArr.disableFileNames =disableFileTxt;
          var disableMemberArr = res.data.disableMembers || []
          var disableIdArr = []
          disableMemberArr.map((item)=>{
            disableIdArr.push(item.id)
          })
          that.goodArr.disableMembers = disableIdArr
          that.goodArr.channelOwner = res.data.channelOwner ? res.data.channelOwner.id : null
          that.goodArr.multiExpressLines =res.data.multiExpressLines
          that.goodArr.returnNotifyMember = res.data.returnNotifyMember ? res.data.returnNotifyMember.id : null
          that.goodArr.activeObj = res.data.returnNotifyMember
          that.getGroupList(res.data.botId)
          that.getGroupMember()

        }else{
          this.$message.error(res.message)
        }
      })
    },
    cancalFun(){
      this.$router.go(-1)
    },
    
    addClass(){
      this.classflag = true
    },
    ownerClicks(val){
      var that = this
      that.memberoptions.map((item)=>{
        if(item.id == val){
          that.goodArr.ownerObj = item
        }
      })
    },
    activeClicks(val){
      var that = this
      that.memberoptions.map((item)=>{
        if(item.id == val){
          that.goodArr.activeObj = item
        }
      })
    },
    contactClicks(val){
      var that = this
      console.log(val)
    },
    qunClicks(e){
      var that = this
      that.Groupoptions.map((item)=>{
        if(item.id == e){
          that.goodArr.groupId = e
          that.goodArr.groupName = item.name
          that.goodArr.name = item.name
        }
      })
      that.getGroupMember(e)
    },
    submitFun(){
      var that = this
      var params = {
        autoReturnRules: {type: ''}
      }
      if(!that.goodArr.name) { that.$message.error('请输入分销商名称'); return false }
      console.log( that.goodArr)
      params.autoReturnRules.type = that.goodArr.autoReturnRules.type
      params.disableFileNames = that.goodArr.disableFileNames ? that.goodArr.disableFileNames.replace("，",",").split(','):[];
      params.robotId = that.goodArr.robotId;
      params.autoReturn = that.goodArr.autoReturn
      params.returnFileHandler = that.goodArr.returnFileHandler
      params.autoReceive = that.goodArr.autoReceive
      params.valid = that.goodArr.valid
      params.name = that.goodArr.name
      params.channelOwner = that.goodArr.ownerObj
      params.groupId = that.goodArr.groupId
      params.groupName = that.goodArr.groupName
      params.multiExpressLines = that.goodArr.multiExpressLines
      params.remark = that.goodArr.remark;
      params.returnNotifyMember = that.goodArr.activeObj
      params.deadlines = that.deadlines.map(it => it.value).filter(it => it != '')          

      var disableMembersArr = []
      if(that.goodArr.disableMembers.length > 0){
        that.memberoptions.map((item)=>{
          that.goodArr.disableMembers.map((its)=>{
            if(item.id == its){
              disableMembersArr.push(item)
            }
          }) 
        })
      }
      params.disableMembers = disableMembersArr
      params.autoReceiptWhenReturn = that.goodArr.autoReceiptWhenReturn
      params.autoReceiptWhenReceive = that.goodArr.autoReceiptWhenReceive

      console.log(params)
      that.loading = true
      if(that.goodArr.id){
        params.id = that.goodArr.id
        configRetailerGroup(params).then(res => {
          if(res.code === 200){
            that.$message({
              message: '编辑成功',
              type: 'success'
            })
            setTimeout(() =>{
              that.$router.push({
                path: "/channel"
              })
            }, 300)
          }else{
            that.$message.error(res.message)
          }
          that.loading = false
        })
      }else{
        configRetailerGroup(params).then(res => {
          if(res.code === 200){
            that.$message({
              message: '创建成功',
              type: 'success'
            })
            setTimeout(() =>{
              that.$router.push({
                path: "/channel"
              })
            }, 300)
          }else{
            that.$message.error(res.message)
          }
          that.loading = false
        })
      }
    
    },
  },
  mounted() {
    var goodid = this.$route.query.id
    var qunid = decodeURI(this.$route.query.qid)
    var boname = this.$route.query.name
    console.log(goodid)
    if(goodid){
      this.getDetail(goodid)
    }
    this.getBodList()
    if(boname && qunid){
      this.boname = boname
      this.qid = qunid
      this.goodArr.botId = boname
      this.goodArr.groupId = qunid
      this.getGroupList(boname)
      this.getGroupMember()
    }
    
  }
}
</script>

<style scoped>
.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.tips{
  font-size: 12px;
  color: #999;
  margin-left:10px;
}
.timebox{
  width: 300px;
  display: block;
}
.additem{
  width: 32px;
  height: 32px;
  display: block;
  float:right;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  background-color: #07C160;
  margin-left: 10px;
  cursor: pointer;
}
.delitem{
  width: 32px;
  height: 32px;
  display: block;
  float:right;
  color: #999;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  background-color: #f3f3f3;
  margin-left: 10px;
  cursor: pointer;
}
</style>